import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Components
import Modal from "./Modals";
import Subscriptions from "./Modals/Subscriptions";
import { resetIsKeywordAvailable, resetIsShortedLink } from "../actions/dashboard"
import { resetAfterLoggedInFlag } from "../actions/auth"

const EnterUrl = ({
    history, currentUser, keywordsPages, newKeyword, handleSubmit, isDashboard, afterLoggedIn,
    modelOpen, handleKeywordLookup, handleShortLink,
    isKeywordAvailable, isKeywordUnavailable, isShortedLink, getKeywords,
    resetIsKeywordAvailable, resetIsShortedLink, resetAfterLoggedInFlag, locationState
  }) => {
  const [url, setUrl] = useState("");
  const [confirmUrl, setConfirmUrl] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [description, setDescription] = useState("");
  const [isOpenMadal, setIsOpenMadal] = useState(false);
  const [existingKeyword, setExistingKeyword] = useState({});

  useEffect(() => {
    if(locationState && locationState.isSubsucribe === 'true') {
      setExistingKeyword(locationState)
      setIsOpenMadal(true)
    }
  }, [locationState])

  useEffect(() => {
    if(isDashboard && afterLoggedIn) {
      resetAfterLoggedInFlag();
      if(localStorage.keyword) {
        handleShortLink({
          url: localStorage.url,
          keyword: localStorage.keyword,
          description: localStorage.description
        })
        clearValues()
        setIsOpenMadal(true)
      }
    }
  }, [afterLoggedIn])

  useEffect(() => {
    if(isKeywordAvailable && isDashboard && !afterLoggedIn) {
      setIsOpenMadal(true)
      handleShortLink({
        url: url,
        keyword: keyword,
        description: description
      })
      resetIsKeywordAvailable()
    }
  }, [isKeywordAvailable])

  useEffect(() => {
    if(isShortedLink) {
       isDashboard && getKeywords({page: keywordsPages.current_page, history: history})
       resetIsShortedLink()
     }
  }, [isShortedLink])

  const checkUrl = () => {
    // eslint-disable-next-line
    const result = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(
      url
    );
    
    !url.includes("123.ch") && result && url.length ? setConfirmUrl(true) : setConfirmUrl(false);
  };

  const checkKeywordDescription = () => {
    if (confirmUrl && keyword) {
      handleKeywordLookup({
        url,
        keyword,
        description
      })
    } else {
      setAlert(false);
    }
  };

  const storeValues = () => {
    localStorage.setItem("url", url)
    localStorage.setItem("keyword", keyword)
    localStorage.setItem("description", description)
    return true
  }

  const clearValues = () => {
    localStorage.removeItem("url")
    localStorage.removeItem("keyword")
    localStorage.removeItem("description")
    return true
  }

  const setOrigionalUrl = (event) =>{
    let url = event.target.value
    setUrl(url)
    url.includes("123.ch") && setConfirmUrl(false);
  }

  const clearUrlValues = (id) => {
    setIsOpenMadal(false)
    setConfirmUrl(false);
    setUrl("");
    setKeyword("");
    setDescription("");
  }

  return (
    <Fragment>
      <form
        onSubmit={e => {
          e.preventDefault();
          checkUrl(url);
          if (confirmUrl && keyword) {
            checkKeywordDescription();
          } else {
            checkUrl(false);
          }
        }}
        className="enterUrl"
      >
        <div className="enterUrl_wrapInputs">
          <input
            placeholder="Enter Original URL..."
            className="input-default "
            value={url}
            onChange={setOrigionalUrl}
          />
          {confirmUrl && (
            <div className="enterUrl_wrapActionInputs">
              <input
                placeholder="Enter Keyword..."
                className="input-default "
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
                autoFocus={true}
              />
              <input
                placeholder="Enter Description..."
                className="input-default "
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </div>
          )}
        </div>
        <button
          className="btn default"
        >
          Shorten URL
        </button>
      </form>
      {isKeywordAvailable && !isDashboard && storeValues() && (
        <div className="enterUrl_alert">
          Congratulations, this keyword is available. Please{" "}
          {/* eslint-disable-next-line */}
          <a onClick={()=>modelOpen('Login')}>Login</a> /{" "}
          {/* eslint-disable-next-line */}
          <a onClick={()=>modelOpen('Register')}>Register</a>
        </div>
      )}
      {isKeywordUnavailable && (
        <div className="enterUrl_alert">
          Sorry, this keyword is not available. Please try again.
        </div>
      )}

      {/* Modals */}
      <Modal
        open={isOpenMadal}
        close={() => setIsOpenMadal(false)}
        customClass={"SubscriptionsModal"}
      >
        <Subscriptions keyword={newKeyword} existingKeyword={existingKeyword} highlightRow={clearUrlValues} />
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  // currentUser: state.auth.currentUser,
  isKeywordAvailable: state.dashboard.isKeywordAvailable,
  isKeywordUnavailable: state.dashboard.isKeywordUnavailable,
  afterLoggedIn: state.auth.afterLoggedIn,
  keywordsPages: state.table.pages,
  newKeyword: state.dashboard.keyword
});

const mapDispatchToProps = {
  resetIsKeywordAvailable,
  resetIsShortedLink,
  resetAfterLoggedInFlag
}


EnterUrl.propTypes = {
  currentUser: PropTypes.object,
  handleSubmit: PropTypes.func,
  modelOpen: PropTypes.func,
  isDashboard: PropTypes.bool,
  handleKeywordLookup: PropTypes.func,
  handleShortLink: PropTypes.func,
  isKeywordAvailable: PropTypes.bool,
  isKeywordUnavailable: PropTypes.bool,
  afterLoggedIn: PropTypes.bool,
  keywordsPages: PropTypes.object,
  newKeyword: PropTypes.object,
  isShortedLink: PropTypes.bool,
  resetIsKeywordAvailable: PropTypes.func,
  resetIsShortedLink: PropTypes.func,
  getKeywords: PropTypes.func,
  resetAfterLoggedInFlag: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterUrl);
