import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Components
import Modal from "../../components/Modals";
import NotifyCreater from "../../components/Modals/NotifyCreater";
import EnterUrl from "../../components/EnterUrl";
import Table from "../../components/Table";
import { logout } from '../../actions/auth';
import API from '../../helpers/API'

// Actions
import { handleSubmitUrl, handleKeywordLookup, handleShortLink, getKeywords, resetIsKeywordAvailable } from '../../actions/dashboard';

const Dashboard = ({ logout, history, location, handleSubmitUrl, currentUser, handleShortLink, handleKeywordLookup, isKeywordAvailable, isKeywordUnavailable, isShortedLink, getKeywords}) => {
  // Madal
  const [isOpenMadal, setIsOpenMadal] = useState(false);
  const [isOpenSubscriptionMadal, setIsOpenSubscriptionMadal] = useState(false);
  const [isNotifyOpenModal, setIsNotifyOpenModal] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const openMenu = () => {
    const val = !isOpenMenu
    setIsOpenMenu(val)
  }

  const makeShortLink = () => {
    if(localStorage.getItem("url") && localStorage.getItem("keyword") && localStorage.getItem("description")) {
      setIsOpenSubscriptionMadal(true)
      handleShortLink({
        url: localStorage.getItem("url"),
        keyword: localStorage.getItem("keyword"),
        description: localStorage.getItem("description")
      })
      localStorage.removeItem("url")
      localStorage.removeItem("keyword")
      localStorage.removeItem("description")
    }
  }

  useEffect(() => {
    const userInfo =  JSON.parse(localStorage.getItem("currentUser"))
    const userEmail = userInfo.email
    setUserEmail(userEmail)
  }, [])
  
  useEffect(() => {
    if(location.state && location.state.myKW == "false") {
      console.log("current user", localStorage.getItem("currentUser"))
      setIsNotifyOpenModal(true)
      location.state.myKW = undefined;
    }
  }, [location]);

  useEffect(() => {
    makeShortLink()
  }, [])

  const sendEmail = async (email) => {
    const data = {
      keyword: location.state ? location.state.keyword : '',
      email: email
    }
    await API.sendExpiredEmail(data);
    setIsNotifyOpenModal(false)
  } 

  return (
    <Fragment>
      {/* Header */}
        <div className="header">
          <div className="header_logo" onClick={() => history.push("/")}>
            <div className="header_logo_images">
              <img src="/images/logo.svg" alt="" />
            </div>
            <div className="header_logo_text">Link shortener</div>
          </div>
          <div className="header_btnWrap">
          <button
              className="btn link"
              onClick={() => {
                history.replace("account")
              }}
            >
              Account
            </button>
            <button
              className="btn link"
              onClick={() => {
                logout({history: history});
                window.localStorage.removeItem("SubscriptionsModal");
              }}
            >
              Logout
            </button>
          </div>
          <div className="header_hamburger_menu">
          <button className="hamburger_button"><img src="/images/menu.png" alt="my image" onClick={openMenu} /></button>
            {isOpenMenu && 
            <>
            <div className="header_hamburger_submenu">
          <button
              className="btn link"
              onClick={() => {
                history.replace("account")
              }}
            >
              Account
            </button>
            <button
              className="btn link"
              onClick={() => {
                logout({history: history});
                window.localStorage.removeItem("SubscriptionsModal");
              }}
            >
              Logout
            </button>
            </div>
            </>
          }
          </div>
        </div>
      {/* Header end */}

      {/* Enter url  */}
      <EnterUrl isDashboard={true}
        handleSubmit={handleSubmitUrl}
        handleKeywordLookup={handleKeywordLookup}
        handleShortLink={handleShortLink}
        // isKeywordAvailable={isKeywordAvailable}
        // isKeywordUnavailable={isKeywordUnavailable}
        isShortedLink={isShortedLink}
        currentUser={currentUser}
        getKeywords={getKeywords}
        locationState={location.state}
       />
      {/* Enter url end */}

      {/* Table  */}
      <Table history={history} location={location} isOpenSubscriptionMadal={isOpenSubscriptionMadal} isShortedLink={isShortedLink} />
      {/* Table end */}

      {/* Simplify your links */}
      <div className="simplifyYourLinks">
        <img
          className="simplifyYourLinks-images"
          src="/images/logo.svg"
          alt="logo"
        />
        <div className="simplifyYourLinks-text">simplify your links</div>
      </div>
      {/* Simplify your links end */}

      {/* Modals */}
      <Modal open={isOpenMadal} close={() => setIsOpenMadal(false)}></Modal>
      {/* Modals end */}
      {/* Modals */}
      <Modal
        open={isNotifyOpenModal}
        close={() => setIsNotifyOpenModal(false)}
      >
        <NotifyCreater
          close={() => setIsNotifyOpenModal(false)}
          handleSubmit={({ value }) => sendEmail(value)}
          editItemValue= {userEmail}
          text="Expired Link"
          placeholder="New email..."
          inputName="email"
          buttonText="Send Email"
        />
      </Modal>
      {/* Modals end */}
    </Fragment>
  );
};


const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  // isKeywordAvailable: state.dashboard.isKeywordAvailable,
  // isKeywordUnavailable: state.dashboard.isKeywordUnavailable,
  isShortedLink: state.dashboard.isShortedLink
});

Dashboard.propTypes = {
  currentUser:PropTypes.object,
  handleKeywordLookup: PropTypes.func,
  handleShortLink: PropTypes.func,
  // isKeywordAvailable: PropTypes.bool,
  // isKeywordUnavailable: PropTypes.bool,
  isShortedLink: PropTypes.bool,
  getKeywords: PropTypes.func,
  logout: PropTypes.func,
};

const mapDispatchToProps = {
  handleSubmitUrl,
  handleKeywordLookup,
  handleShortLink,
  getKeywords,
  logout,
};


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
