import useComponentVisible from "../hooks/useComponentVisible";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from 'react-toast-notifications'

const DropDown = ({ handleselect, url }) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);
  const { addToast } = useToasts()

  return (
    <div ref={ref}>
      <span className="dpowDown">
        <img
          onClick={() => setIsComponentVisible(true)}
          className={isComponentVisible ? "icon_info active" : "icon_info"}
          src="/images/right-arrow.svg"
          alt="info"
        />
        {isComponentVisible && (
          <div className="dpowDown_content">
            <CopyToClipboard text={`https://123.ch/${url}`}>
              <div
                className="dpowDown_item"
                onClick={() => {
                  addToast('Link Copied Successfully', {
                    appearance: 'success',
                  })
                  setIsComponentVisible(false);
                }}
              >
                <img
                  src="/images/link.svg"
                  alt="link"
                  className="dpowDown_item_icon"
                />
                Copy Link
              </div>
            </CopyToClipboard>
            <div
              className="dpowDown_item"
              onClick={() => {
                handleselect("fb");
                window.open(`http://www.facebook.com/sharer.php?u=https://123.ch/${url}/`, '_blank');
                setIsComponentVisible(false);
              }}
            >
              <img
                src="/images/facebook.svg"
                alt="link"
                className="dpowDown_item_icon"
              />
              Facebook
            </div>
            <div
              className="dpowDown_item"
              onClick={() => {
                handleselect("tw");
                window.open(`http://twitter.com/share?url=https://123.ch/${url}/`, '_blank');
                setIsComponentVisible(false);
              }}
            >
              <img
                src="/images/twitter.svg"
                alt="link"
                className="dpowDown_item_icon"
              />
              Twitter
            </div>
            <div
              className="dpowDown_item"
              onClick={() => {
                handleselect("ln");
                window.open(`https://www.linkedin.com/shareArticle?mini=true&url=https://123.ch/${url}/`, '_blank');
                setIsComponentVisible(false);
              }}
            >
              <img
                src="/images/linkedin.svg"
                alt="link"
                className="dpowDown_item_icon"
              />
              Linkedin
            </div>
            <div
              className="dpowDown_item"
              onClick={() => {
                // handleselect("mail");
				window.location.href = `mailto:?subject=Check this out&body=https://123.ch/${url}/`;
                setIsComponentVisible(false);
              }}
            >
              <img
                src="/images/mail.svg"
                alt="link"
                className="dpowDown_item_icon"
              />
              Mail
            </div>
          </div>
        )}
      </span>
    </div>
  );
};

export default DropDown;
