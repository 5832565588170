import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Pagination from 'react-paginating';
import queryString from 'query-string';
import * as moment from 'moment';
import ReactTooltip from "react-tooltip";
import Switch from "react-switch";


// Components
import Checkbox from "../Checkbox";
import DropDown from "../DropDown";
import Modal from "../Modals";
import EditModal from "../Modals/EditModal";
import Subscriptions from "../Modals/Subscriptions";

// api
import API from "../../helpers/API";

// Actions
import {
  handleSubmitUrl,
  removeTableItem,
  handleselect,
  handleChecked,
  removeTableItems,
  handleEditDescription,
  handleEditUrl,
  removeClickKeyword,
  reduceClickKeyword,
  changeExpiredTableItem,
  getKeywords
} from "../../actions/dashboard";

export const Table = ({
  history,
  location,
  table,
  keywordsPages,
  isOpenSubscriptionMadal,
  removeTableItem,
  removeTableItems,
  handleselect,
  handleChecked,
  handleEditDescription,
  handleEditUrl,
  removeClickKeyword,
  reduceClickKeyword,
  changeExpiredTableItem,
  getKeywords,
  isShortedLink
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [checkedKeywords, setCheckedKeywords] = useState(false);
  const [isOpenMadal, setIsOpenMadal] = useState(false);
  const [typeMadal, setTypeMadal] = useState("");
  const [editItem, setEditItem] = useState("");
  const [subscriptionItem, setSubscriptionItem] = useState({});
  const [sortName, setSortName] = useState("created_at");
  const [sortType, setSortType] = useState("DESC");
  const [showExpired, setShowExpired] = useState(true);
  const [recordPerPage, setRecordPerPage] = useState("50");

  useEffect(() => {
    const queryObj = queryString.parse(location.search)
    const defaultPage = queryObj.page ? queryObj.page : 1
    setTimeout(()=>{
      history.push({
        pathname: '/dashboard',
        search: `?page=${defaultPage}`,
        state: location.state
      })
      getKeywords({page: defaultPage, history: history})
    }, 300)
  }, [])

  useEffect(() => {
    if (isOpenSubscriptionMadal) {
      setTypeMadal("SubscriptionsModal");
      setIsOpenMadal(true);
    }
  }, [isOpenSubscriptionMadal]);

  useEffect(() => {
    const checked = table.filter(elem => elem.checked);
    const keywords = checked.map(elem => elem.keyword);
    setCheckedKeywords(keywords.join(", "));
    setIsChecked(checked.length);
  }, [table, handleChecked]);

  useEffect(() => {
    if(isShortedLink) {
      setSortName("created_at")
      setSortType("DESC")
    }
  }, [isShortedLink]);

  const escFunction = (event) => {
    if(event.keyCode === 27) {
      setIsOpenMadal(false)
    }
  };
  
  useEffect(() => {
    (async function() {
      if(location.state && location.state.myKW == "true") {
        let id = parseInt(location.state.elem_id);
        const element = await API.getKeyword(id);
        if(element.data != undefined){
          setEditItem(element.data);
          setIsOpenMadal(true);
          setTypeMadal("ExpiredModal");
        }
        location.state.myKW = undefined;
    }
    })()
  }, [location]);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const getCurrentPage = () => {
    const queryObj = queryString.parse(location.search)
    return queryObj.page ? queryObj.page : 1
  }

  const filterExpired = (tempTable) => {
    return tempTable.filter(item => item.expire_on !== "expired");
  }

  const sortTable = (tempTable) => {
    if(!showExpired) tempTable = filterExpired(table);
    return tempTable
    // if(sortName === "created_at") {
    //   return tempTable;
    // } else {
    //   return tempTable.sort(
    //     (a,b) => a[sortName] > b[sortName] ? (sortType === "DESC" ? 1 : -1) : (sortType === "DESC" ? -1 : 1)
    //   )
    // }
  }

  const toTime = (value) => {
    moment(value, "YYYY-MM-DD hh:mm")
  }

  // sortTable(table)

  useEffect(() => {
    const defaultPage = getCurrentPage();
    getKeywords({page: defaultPage, columnName: sortName, order: sortType, recordPerPage: recordPerPage, history: history})
  }, [sortType, sortName]);

  const handleSortTable = (columnName) => {
    if(columnName === sortName) {
      switch(sortType) {
        case 'DESC':
          setSortType("ASC")
          return
        case 'ASC':
          setSortName("created_at")
          setSortType("DESC")
          return
        default:
          return
      }
    } else {
      setSortName(columnName)
      setSortType("DESC")
      return
    }
  }

  const changePage = ({page}) => {
    getKeywords({page: page, columnName: sortName, order: sortType, history: history})
    history.push(`/dashboard?page=${page}`)
  }

  const changeRecordPerPage = (event) => {
    const value = event.target.value;
    setRecordPerPage(value);
    const defaultPage = getCurrentPage();
    getKeywords({page: defaultPage, columnName: sortName, order: sortType, recordPerPage: value, history: history});
  }

  const highlightUpdatedRow = (id) => {
    setIsOpenMadal(false)
    var element = document.getElementById(id)
    element.classList.add("cell_update");
  }
  return (
    <Fragment>
      <div style={{position: 'relative'}}>
        <div className="tbl_header_switch">
          <span className="tbl_filter_text tbl_filter_text_position">Show Expired Links:</span> <Switch onColor="#FFFFFF" onHandleColor="#888888" uncheckedIcon={false} checkedIcon={false} onChange={setShowExpired} checked={showExpired}/>
        </div>
        <div className="tbl_header_records">
          <span className="tbl_filter_text">Per Page:</span> 
          <select style={{height: 30, marginLeft: 10}} onChange={changeRecordPerPage} value={recordPerPage}>
            <option value="50">50</option>
            <option value="all">All</option>
          </select>
        </div>
      </div>
      <div className="tbl_header">
          Your Links
      </div>
      <div className="tbl">
        <div className="row">
          <div className="cell cell_icon">
            {isChecked ? (
              <img
                onClick={() => {
                  setIsOpenMadal(true);
                  setTypeMadal("DeleteModalMulti");
                }}
                // onClick={removeTableItems}
                className="icon_delete"
                src="/images/delete.svg"
                alt="delete"
              />
            ) : (
              ""
            )}
          </div>
          <div className="cell cell_header cell_link" onClick={(e) => handleSortTable("url")}>
            <div className="header_clickable">Original URL</div>
            {sortName === "url" && (
              sortType === "DESC" ? (<i className="caret caret-down"></i>) : (<i className="caret caret-up"></i>)
              )}
          </div>
          <div className="cell cell_header cell_keywords" onClick={(e) => handleSortTable("keyword")}>
          <div className="header_clickable">Keyword</div>
            {sortName === "keyword" && (
              sortType === "DESC" ? (<i className="caret caret-down"></i>) : (<i className="caret caret-up"></i>)
            )}
          </div>
          <div className="cell cell_header cell_expiration" onClick={(e) => handleSortTable("expire_on")}>
            <div className="header_clickable">Expiration</div><span>CET</span>
            {sortName === "expire_on" && (
              sortType === "DESC" ? (<i className="caret caret-down"></i>) : (<i className="caret caret-up"></i>)
            )}
          </div>
          <div className="cell cell_header cell_click click-right-align" onClick={(e) => handleSortTable("clicks")}>
            <div className="header_clickable">Clicks
              {sortName === "clicks" && (
                sortType === "DESC" ? (<i className="caret caret-down"></i>) : (<i className="caret caret-up"></i>)
              )}
            </div>
          </div>
        </div>
        {sortTable(table).map(elem => {
          if (elem.status === "pending" || elem.status === "active") {
            return (
              <div className={`row ${elem.status}`} id={elem.id} key={elem.id}>
                <div className="cell cell_icon">
                  <Checkbox
                    checked={elem.checked}
                    handleChecked={checked =>
                      handleChecked({ id: elem.id, checked })
                    }
                  />
                </div>
                <div className="cell cell_link">
                  <div className="wrap_link">{
                    elem.url.includes("https://") ? elem.url.replace("https://", "") : elem.url.replace("http://", "")
                  }</div>
                  <img
                    className="icon_edit"
                    src="/images/edit.svg"
                    alt="edit"
                    onClick={() => {
                      setEditItem(elem);
                      setIsOpenMadal(true);
                      setTypeMadal("EditUrlModal");
                    }}
                  />
                </div>
                <div className="cell cell_keywords">
                  <div className="wrap_keywords clickable_link" data-tip={elem.keyword} data-for={'key'+elem.keyword}
                  onClick={() => {
                    console.log(elem.id);
                    history.push({
                      pathname: '/statistics',
                      search: `?keyword=${elem.id}&page=1`,
                    })
                  }}>{elem.keyword}</div>
                  <ReactTooltip id={'key'+elem.keyword}>{'123.ch/'+elem.keyword}</ReactTooltip>
                    
                  <img
                    className="icon_info"
                    src="/images/info.svg"
                    data-tip={elem.description}
                    data-for={'key'+elem.description}
                    alt="info"
                    onClick={() => {
                      setEditItem(elem);
                      setIsOpenMadal(true);
                      setTypeMadal("EditDescriptionModal");
                    }}
                  />
                  <ReactTooltip id={'key'+elem.description}>{elem.description}</ReactTooltip>
                </div>
                {
                  elem.status === "active" ?
                    <div className="cell cell_expiration">
                      <p data-tip={elem.expire_on} data-for={'exp'+elem.keyword}>{elem.expire_on}</p>
                      <ReactTooltip className="exp_tooltip" id={'exp'+elem.keyword}>The next payment for this subscription will be charged to your credit card on</ReactTooltip>
                    </div>
                  :
                  <div className="cell cell_expiration">
                    {elem.expire_on}
                  </div>
                }
                <div className="cell cell_click">
                  {elem.clicks ? (
                    <div
                      className="clicks text_hover"
                      onClick={() => {
                        setEditItem(elem);
                        setIsOpenMadal(true);
                        setTypeMadal("ClickModal");
                      }}
                      data-tip={elem.last_three_clicks} data-for={'key'+elem.clicks} data-multiline={true}
                    >
                      {elem.clicks}
                      <ReactTooltip data-html={true} multiline={true} id={'key'+elem.clicks} />
                    </div>
                  ) : (
                    <div className='clicks'>{elem.clicks}</div>
                  )}
                  <span className="cell_wrapIcons">
                    {
                      elem.status === "active" ? <DropDown handleselect={handleselect} url={elem.keyword} /> :
                      <img
                      className="icon_share"
                      src="/images/exclamation-sign.svg"
                      alt="edit"
                      onClick={() => {
                        setEditItem(elem);
                        setIsOpenMadal(true);
                        setTypeMadal("ExpiredModal");
                      }}
                    />
                    }
                  </span>
                </div>
                <div className="cell cell_calender">
                  {elem.is_twenty_percent_subscription_left === true && 
                   <img
                   className="calendar-plus-line"
                   src="/images/calendar-plus-line.svg"
                   alt="edit"
                   onClick={() => {
                    setSubscriptionItem(elem);
                    setTypeMadal("SubscriptionsModal");
                    setIsOpenMadal(true);
                   }}
                   />
                  }
                </div>
              </div>
            );
          } else {
            return (
              <div className={`row ${elem.status}`} key={elem.id}>
                <div className="cell cell_icon">
                  <Checkbox
                    checked={elem.checked}
                    handleChecked={checked =>
                      handleChecked({ id: elem.id, checked })
                    }
                  />
                </div>
                <div className="cell cell_link">
                  <div className="wrap_link">{
                    elem.url.includes("https://") ? elem.url.replace("https://", "") : elem.url.replace("http://", "")
                  }</div>
                  
                  <img
                    className="icon_edit"
                    src="/images/edit.svg"
                    alt="edit"
                    onClick={() => {
                      setEditItem(elem);
                      setIsOpenMadal(true);
                      setTypeMadal("EditUrlModal");
                    }}
                  />
                </div>
                <div className="cell cell_keywords">
                  <div className="wrap_keywords clickable_link" data-tip={elem.keyword} data-for={'key'+elem.keyword}
                  onClick={() => {
                    history.push({
                      pathname: '/statistics',
                      search: `?keyword=${elem.id}&page=1`,
                    })
                  }}
                  >{elem.keyword}</div>
                  <ReactTooltip id={'key'+elem.keyword}>{'123.ch/'+elem.keyword}</ReactTooltip>
                  
                  <img
                    className="icon_info"
                    src="/images/info.svg"
                    alt="info"
                    onClick={() => {
                      setEditItem(elem);
                      setIsOpenMadal(true);
                      setTypeMadal("EditDescriptionModal");
                    }}
                  />
                </div>
                <div
                  className="cell cell_expiration text_hover"
                  onClick={() => {
                    setEditItem(elem);
                    setIsOpenMadal(true);
                    setTypeMadal("ExpiredModal");
                  }}
                >
                  <p data-tip={elem.expire_on} data-for={'exp'+elem.keyword}>{elem.expire_on}</p>
                  <ReactTooltip id={'exp'+elem.keyword}>{elem.end_date}</ReactTooltip>
                </div>
                <div className="cell cell_click">
                  {elem.clicks ? (
                    <div className="clicks text_hover" data-tip={elem.last_three_clicks} data-for={'key'+elem.clicks} data-multiline={true}>
                      {elem.clicks}
                      <ReactTooltip data-html={true} multiline={true} id={'key'+elem.clicks} />
                    </div>
                  ) : (
                    <div className='clicks'>{elem.clicks}</div>
                  )}
                  <span className="cell_wrapIcons">
                    <img
                      className="icon_share"
                      src="/images/exclamation-sign.svg"
                      alt="edit"
                      onClick={() => {
                        setEditItem(elem);
                        setIsOpenMadal(true);
                        setTypeMadal("ExpiredModal");
                      }}
                    />
                  </span>
                </div>
              </div>
            );
          }
        })}
        <div className="pagination">
          {
            keywordsPages &&
            <Pagination
            className="bg-red pagination-container"
            total={keywordsPages.total * 50}
            limit={50}
            pageCount={3}
            currentPage={keywordsPages.current_page}
          >
            {({
              pages,
              currentPage,
              hasNextPage,
              hasPreviousPage,
              previousPage,
              nextPage,
              totalPages,
              getPageItemProps
            }) => (
              <div className="pagination-buttons">
                <button
                  {...getPageItemProps({
                    pageValue: 1,
                    onPageChange: () => {changePage({page: 1})},
                  })}
                >
                  first
                </button>

                {hasPreviousPage && (
                  <button
                    {...getPageItemProps({
                      pageValue: previousPage,
                      onPageChange: () => {changePage({page: previousPage})}
                    })}
                  >
                    {'<'}
                  </button>
                )}

                {pages.map(page => {
                  let activePage = null;
                  if (currentPage === page) {
                    activePage = { backgroundColor: 'white', border:'inset', fontWeight: 'bold' };
                  }
                  return (
                    <button
                      {...getPageItemProps({
                        pageValue: page,
                        key: page,
                        style: activePage,
                        onPageChange: () => {changePage({page: page})}
                      })}
                    >
                      {page}
                    </button>
                  );
                })}

                {hasNextPage && (
                  <button
                    {...getPageItemProps({
                      pageValue: nextPage,
                      onPageChange: () => {changePage({page: nextPage})}
                    })}
                  >
                    {'>'}
                  </button>
                )}

                <button
                  {...getPageItemProps({
                    pageValue: totalPages,
                    onPageChange: () => {changePage({page: totalPages})}
                  })}
                >
                  last
                </button>
              </div>
            )}
          </Pagination>
          }
          
        </div>
      </div>

      {/* Modals */}
      <Modal
        open={isOpenMadal}
        close={() => setIsOpenMadal(false)}
        customClass={typeMadal}
      >
        {typeMadal === "EditDescriptionModal" && (
          <EditModal
            close={() => setIsOpenMadal(false)}
            handleSubmit={handleEditDescription}
            editItemId={editItem.id}
            editItemValue={editItem.description}
            text="Link Description"
            placeholder="Enter description..."
            inputName="description"
            buttonText="Save Changes"
          />
        )}
        {typeMadal === "EditUrlModal" && (
          <EditModal
            close={() => setIsOpenMadal(false)}
            handleSubmit={handleEditUrl}
            editItemId={editItem.id}
            editItemValue={editItem.url}
            text="Original URL"
            placeholder="Enter url..."
            inputName="url"
            buttonText="Save Changes"
          />
        )}
        {typeMadal === "DeleteModal" && (
          <EditModal
            close={() => setIsOpenMadal(false)}
            handleSubmit={removeTableItem}
            editItemId={editItem.id}
            description={`Are you sure you want to permanently delete [${editItem.keyword}] ?`}
            text="Delete Link"
            buttonText="Confirm"
          />
        )}
        {typeMadal === "DeleteModalMulti" && (
          <EditModal
            close={() => setIsOpenMadal(false)}
            handleSubmit={removeTableItems}
            description={`Are you sure you want to permanently delete [${checkedKeywords}] ?`}
            text="Delete Links"
            buttonText="Confirm"
          />
        )}
        {typeMadal === "ClickModal" && (
          <EditModal
            clickModal
            close={() => setIsOpenMadal(false)}
            handleSubmit={removeClickKeyword}
            handleReduceSubmit={reduceClickKeyword}
            editItemId={editItem.id}
            description=""
            text="Clicks correction"
            buttonText="Confirm"
          />
        )}
        {typeMadal === "ExpiredModal" && (
          <EditModal
            expiredModal
            description="Start a new subscription for this link?"
            close={() => setIsOpenMadal(false)}
            handleSubmit={({ id, value }) => {
              changeExpiredTableItem({ id: id });
              setSubscriptionItem(table.find(elem => elem.id === id));
              setTimeout(() => {
                setTypeMadal("SubscriptionsModal");
                setIsOpenMadal(true);
              }, 500);
            }}
            editItemId={editItem.id}
            editItemKeyword={`123.ch/${editItem.keyword}`}
            text="Expired Link"
            buttonText="Yes"
          />
        )}

        {typeMadal === "SubscriptionsModal" && <Subscriptions keyword={subscriptionItem} highlightRow={highlightUpdatedRow} history={history} />}
      </Modal>

      {/* Modals end */}
    </Fragment>
  );
};

Table.propTypes = {
  table: PropTypes.array,
  keywordsPages: PropTypes.object,
  removeTableItem: PropTypes.func,
  handleselect: PropTypes.func,
  removeTableItems: PropTypes.func,
  handleEditDescription: PropTypes.func,
  handleChecked: PropTypes.func,
  handleEditUrl: PropTypes.func,
  removeClickKeyword: PropTypes.func,
  reduceClickKeyword: PropTypes.func,
  changeExpiredTableItem: PropTypes.func,
  getKeywords: PropTypes.func,
  isShortedLink: PropTypes.bool
};

const mapStateToProps = state => ({
  table: state.table.table,
  keywordsPages: state.table.pages
});

const mapDispatchToProps = {
  handleSubmitUrl,
  removeTableItem,
  handleselect,
  removeTableItems,
  handleChecked,
  handleEditDescription,
  handleEditUrl,
  removeClickKeyword,
  reduceClickKeyword,
  changeExpiredTableItem,
  getKeywords
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
