import API from '../helpers/API'
import { successCode } from '../helpers/constants'
const ACTIONS = {
  // login
  SHORT_LINK_SUCCESS: "SHORT_LINK_SUCCESS",
  UPDATE_PASSWORD_FAILED: "UPDATE_PASSWORD_FAILED",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
}

const updateStatus = (status, statusInfo) => {
  return {
    type: status,
    data: statusInfo
  }
}

  export const changePassword = ({oldPassword, newPassword, repeatPassword}) => {
    return async (dispatch) => {
      try {
        const result = await API.changePassword({
            password: oldPassword,
            newPassword: newPassword
        });
        if (result.status === successCode) {
          dispatch({type: "UPDATE_PASSWORD_SUCCESS", data: true })
        }
        else {
          dispatch(updateStatus(ACTIONS.UPDATE_PASSWORD_FAILED, result.data.errors))
        }
      } catch (err) {
        dispatch(updateStatus(ACTIONS.UPDATE_PASSWORD_FAILED, err));
        return err;
      }
    }
  }

  export const resetpasswordErrorMessage = () => {
    return (dispatch) => {
      dispatch({type: "RESET_PASSWORD_ERROR_MESSAGE", data: ""})
    }
  }

  export const resetIsPasswordChange = () => {
    return (dispatch) => {
      dispatch({type: "RESET_IS_PASSWORD_CHANGE", data: null}) 
    }
  }