const initialState = {
  // TODO: table: []
  table: [
  ],
  pages: {current_page: 1}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
      case "SET_TABLE":
        return { ...state, table: action.payload};
      case 'GET_KEYWORDS_SUCCESS':
        return {...state, table: action.data.data, pages: action.data.pages};
      case 'GET_KEYWORDS_FAILED':
        return { ...state };
    default:
      return { ...state };
  }
}
