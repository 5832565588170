import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import queryString from 'query-string';

// Pages
import Home from "../pages/home/Home";
import Dashboard from "../pages/dashboard/Dashboard";
import Account from "../pages/account/Account";
import Statistics from "../pages/statistics/Statistics";

import { withRouter } from "react-router-dom";

const PrivateRoute = ({ redirect, ...props }) => {
  if (localStorage.getItem("currentUser")) return <Route {...props} />;
  return <Redirect to="/" />;
};

const Routes = ({ history }) => {
  const path = history.location.pathname;
  const queryObj = queryString.parse(history.location.search);
  console.log(path);
  useEffect(() => {
    const currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      if (path === "/account") {
        history.replace("/account");
        return
      }
      if (path === "/statistics") {
        history.replace("/statistics");
        return
      }
      if (path === "/") {
        history.push({
          pathname: '/dashboard',
          state: queryObj
        });
      }
      history.push({
        pathname: '/dashboard',
        state: queryObj
      });
    } else {
      history.push({
        pathname: '/',
        state: queryObj
      });
    }
  }, [history, path]);

  return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/account" component={Account} />
        <Route exact path="/statistics" component={Statistics} />
        <Route component={Home} />
      </Switch>
  );
};

// Modal.propTypes = {
//   open: PropTypes.bool,
//   close: PropTypes.func,
//   children: PropTypes.node
// };

export default withRouter(Routes);
