import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Field, Formik, ErrorMessage } from "formik";
import * as yup from "yup";

const EditModal = ({
  close,
  handleSubmit,
  passwordErrorMessage,
  resetpasswordErrorMessage,
}) => {

  const [passErrorMessage, setPassErrorMessage] = useState("");

  useEffect(() => {
    passwordErrorMessage === "invalid current password" && setPassErrorMessage(passwordErrorMessage)
    resetpasswordErrorMessage()
  }, [passwordErrorMessage])
  
  return (
    <Fragment>
      <div className="modalTable_header">
      Change Password
        <div className="close_icon" onClick={close} />
      </div>

    
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            repeatPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={value => {
            handleSubmit({oldPassword: value.oldPassword, newPassword: value.newPassword, repeatPassword: value.repeatPassword });
            // close();
          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form className="modalTable_content ">
                  <div className="modalTable_input_wrap">
                  <Field
                    type="password"
                    placeholder={"Password"}
                    name='oldPassword'
                    className="input-default"
                    onChange={e => {
                      setPassErrorMessage("")
                      setFieldValue('oldPassword', e.target.value)
                    }}
                  />
                  <ErrorMessage
                    name='oldPassword'
                    render={msg => <div className="error_input">{msg}</div>}
                  ></ErrorMessage>
                  {passErrorMessage !== "" &&  (
                  <div className="error_input">{passErrorMessage}</div>
              )}
                </div>
                <div className="changePssword">
                <div className="modalTable_input_wrap">
                  <Field
                    type="password"
                    placeholder={"New Password"}
                    name='newPassword'
                    className="input-default"
                  />
                  <ErrorMessage
                    name='newPassword'
                    render={msg => <div className="error_input">{msg}</div>}
                  ></ErrorMessage>
                </div>

                <div className="modalTable_input_wrap">
                  <Field
                    type="password"
                    placeholder="Confirm Password"
                    name="repeatPassword"
                    className="input-default"
                  />
                  <ErrorMessage
                    name="repeatPassword"
                    render={msg => <div className="error_input">{msg}</div>}
                  ></ErrorMessage>
                </div>
                </div>
    

              <button className="btn default" type="submit">
              Save Changes
              </button>
            </Form>
          )}
        </Formik>
        </Fragment>

  );
};
const validationSchema = yup.object().shape({
  oldPassword: yup
      .string()
      .required()
      .required("Enter your password"),
    newPassword: yup
      .string()
      .required()
      .min(8, "Password must be at least 8 characters")
      .max(16, "Password must be at max 8 characters")
      .required("Enter your new password"),
    repeatPassword: yup
    .string()
    .required()
    .min(8, "Password must be at least 8 characters")
    .max(16, "Password must be at max 8 characters")
    .required("Enter your repeat password")
    .test('passwords-match', 'Password must match', function(value) {
        return this.parent.newPassword === value;
    }),
  });

EditModal.propTypes = {
  close: PropTypes.func,
  handleSubmit: PropTypes.func,
  passwordErrorMessage: PropTypes.string,
  resetpasswordErrorMessage: PropTypes.any,
};

export default EditModal;
