const initialState = {
  status: null,
  currentUser: null,
  isOpenMadal: false,
  isLoggedOut: false,
  isLoggedIn: false,
  afterLoggedIn: false,
  loginErrorMessage: "",
  registerErrorMessage: "",
  isPasswordChange: null,
  changePasswordErrorMessage: ""
}
  
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      localStorage.setItem("currentUser", JSON.stringify(action.data))
      return {...state, currentUser: action.data, isOpenMadal: false, isLoggedIn: true, afterLoggedIn: true}
    case 'LOGIN_FAILED':
      return {...state, loginErrorMessage: action.data.response.data.error}
    case 'REGISTER_SUCCESS':
      localStorage.setItem("currentUser", JSON.stringify(action.data))
      return {...state, currentUser: action.data, isOpenMadal: false, isLoggedIn: true, afterLoggedIn: true}
    case 'REGISTER_FAILED':
      return {...state, registerErrorMessage: action.data.response.data.errors}
    case 'LOGOUT_SUCCESS':
      localStorage.removeItem("currentUser")
      return {...state, currentUser: null, isLoggedOut: true, isLoggedIn: false}
    case 'SESSION_EXPIRE':
      localStorage.removeItem("currentUser")
      return {...state, currentUser: null, isLoggedOut: true, isLoggedIn: false}
    case 'LOGOUT_FAILED':
      return state
    case 'CLOSE_MODAL':
      return {...state, isOpenMadal: action.data}
    case 'RESET_LOGIN_ERROR_MESSAGE':
      return {...state, loginErrorMessage: ""}
    case 'RESET_LOGIN_ERROR_MESSAGE':
      return {...state, registerErrorMessage: ""}
    case 'RESET_AFTER_LOGGED_IN_FLAG':
      return {...state, afterLoggedIn: false}
    case 'USER_UPDATE_SUCCESS':
      localStorage.setItem("currentUser", JSON.stringify(action.data))
      return {...state, currentUser: action.data}
    case 'RESET_CURRENT_USER_DATA':
      return {...state, currentUser: action.data}
    case 'RESET_IS_PASSWORD_CHANGE':
      console.log("RESET_IS_PASSWORD_CHANGE", action.data)
      return {...state, isPasswordChange: action.data }
    case 'UPDATE_PASSWORD_SUCCESS':
      return {...state, isPasswordChange: action.data }
    case 'UPDATE_PASSWORD_FAILED':
      return {...state, changePasswordErrorMessage: action.data}
    case 'RESET_PASSWORD_ERROR_MESSAGE':
      return {...state, changePasswordErrorMessage: action.data}
    default:
      return state;
  }
}