import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Form, Field, Formik, ErrorMessage } from "formik";
import * as yup from "yup";

const EditAccountModal = ({
  close,
  handleSubmit,
  firstname,
  lastname,
}) => {
  return (
    <Fragment>
      <div className="modalTable_header">
      My Profile
        <div className="close_icon" onClick={close} />
      </div>

    
        <Formik
          initialValues={{
            firstname: firstname,
            lastname: lastname,
          }}
          validationSchema={validationSchema}
          onSubmit={value => {
            handleSubmit({ firstname: value.firstname, lastname: value.lastname });
            close();
          }}
        >
          {() => (
            <Form className="modalTable_content ">
                <div className="modalTable_input_wrap">
                  <Field
                    type="text"
                    placeholder={"First Name"}
                    name='firstname'
                    className="input-default"
                  />
                  <ErrorMessage
                    name='firstname'
                    render={msg => <div className="error_input">{msg}</div>}
                  ></ErrorMessage>
                </div>

                <div className="modalTable_input_wrap">
                  <Field
                    type="text"
                    placeholder="Last Name"
                    name="lastname"
                    className="input-default"
                  />
                  <ErrorMessage
                    name="lastname"
                    render={msg => <div className="error_input">{msg}</div>}
                  ></ErrorMessage>
                </div>

              <button className="btn default" type="submit">
              Save Changes
              </button>
            </Form>
          )}
        </Formik>
        </Fragment>

  );
};
const validationSchema = yup.object().shape({
    firstname: yup
      .string()
      .required()
      .required("Enter your First Name"),
    lastname: yup
    .string()
    .required()
    .required("Enter your Last Name"),
  });

  EditAccountModal.propTypes = {
  close: PropTypes.func,
  handleSubmit: PropTypes.func,
  firstname: PropTypes.string,
  lastname: PropTypes.string
};

export default EditAccountModal;
