import React, { Fragment} from "react";

import { BrowserRouter as Router, } from "react-router-dom";

import Routes from "../components/Routes";
import { ToastProvider } from 'react-toast-notifications'

// import "./App.scss";

function App() {
  return (
    <Router>
      <Fragment>
        <div className="container">
          <ToastProvider autoDismiss autoDismissTimeout={2500}>
            <Routes />
          </ToastProvider>
        </div>
        <div className="footer">
          <div className="container">
            <div className="footer-copyright1">
              Handmade in Switzerland
            </div>
            <div className="footer-copyright2">© {new Date().getFullYear()} Box. All rights reserved. </div>
            <a className="contact" href="https://boxonline.com/contact-us" target="_blank">Contact</a>
          </div>
        </div>
      </Fragment>
    </Router>
  );
}

export default App;
