import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Components
import Modal from "../../components/Modals";
import NotifyCreater from "../../components/Modals/NotifyCreater";
import EnterUrl from "../../components/EnterUrl";
import StatsTable from "../../components/Table/StatsTable";
import { logout } from '../../actions/auth';
import API from '../../helpers/API'

// Actions
import { handleSubmitUrl, handleKeywordLookup, handleShortLink, getKeywordStats, resetIsKeywordAvailable } from '../../actions/dashboard';

const Dashboard = ({ logout, history, location, handleSubmitUrl, currentUser, handleShortLink, handleKeywordLookup, isKeywordAvailable, isKeywordUnavailable, isShortedLink, getKeywordStats}) => {
  // Madal
  const [isOpenMadal, setIsOpenMadal] = useState(false);
  const [isOpenSubscriptionMadal, setIsOpenSubscriptionMadal] = useState(false);
  const [isNotifyOpenModal, setIsNotifyOpenModal] = useState(false);
  const makeShortLink = () => {
    if(localStorage.getItem("url") && localStorage.getItem("keyword") && localStorage.getItem("description")) {
      setIsOpenSubscriptionMadal(true)
      handleShortLink({
        url: localStorage.getItem("url"),
        keyword: localStorage.getItem("keyword"),
        description: localStorage.getItem("description")
      })
      localStorage.removeItem("url")
      localStorage.removeItem("keyword")
      localStorage.removeItem("description")
    }
  }

  useEffect(() => {
    if(location.state && location.state.isExpired == 'true') {
      setIsNotifyOpenModal(false)
    }
  }, [location]);

  useEffect(() => {
    makeShortLink()
  }, [])


  return (
    <Fragment>
      {/* Header */}
        <div className="header">
          <div className="header_logo" onClick={() => history.push("/")}>
            <div className="header_logo_images">
              <img src="/images/logo.svg" alt="" />
            </div>
            <div className="header_logo_text">Link shortener</div>
          </div>
          <div className="header_btnWrap">
            <button
              className="btn link"
              onClick={() => {
                logout({history: history});
                window.localStorage.removeItem("SubscriptionsModal");
              }}
            >
              Logout
            </button>
          </div>
        </div>
      {/* Header end */}

      {/* Table  */}
      <StatsTable history={history} location={location} />
      {/* Table end */}

      {/* Simplify your links */}
      <div className="simplifyYourLinks">
        <img
          className="simplifyYourLinks-images"
          src="/images/logo.svg"
          alt="logo"
        />
        <div className="simplifyYourLinks-text">simplify your links</div>
      </div>
      {/* Simplify your links end */}

    </Fragment>
  );
};


const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  // isKeywordAvailable: state.dashboard.isKeywordAvailable,
  // isKeywordUnavailable: state.dashboard.isKeywordUnavailable,
  isShortedLink: state.dashboard.isShortedLink
});

Dashboard.propTypes = {
  currentUser:PropTypes.object,
  handleKeywordLookup: PropTypes.func,
  handleShortLink: PropTypes.func,
  getKeywordStats: PropTypes.func,
  logout: PropTypes.func,
};

const mapDispatchToProps = {
  handleSubmitUrl,
  handleKeywordLookup,
  handleShortLink,
  getKeywordStats,
  logout,
};


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
