const initialState = {
  // TODO: table: []
  statistics: [
  ],
  pages: {current_page: 1}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
      case "SET_TABLE":
        return { ...state, statistics: action.payload};
      case 'GET_KEYWORD_STATS_SUCCESS':
        console.log('action');
        console.log(action);
        return {...state, statistics: action.data.data, pages: action.data.pages};
      case 'GET_KEYWORD_STATS_FAILED':
        return { ...state };
    default:
      return { ...state };
  }
}
