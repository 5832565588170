import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Pagination from 'react-paginating';
import queryString from 'query-string';
import * as moment from 'moment';
import ReactTooltip from "react-tooltip";

// Actions
import {
  handleSubmitUrl,
  removeTableItem,
  handleselect,
  handleChecked,
  removeTableItems,
  handleEditDescription,
  handleEditUrl,
  removeClickKeyword,
  reduceClickKeyword,
  changeExpiredTableItem,
  getKeywordStats
} from "../../actions/dashboard";

export const Statistics = ({
  history,
  location,
  statistics,
  keywordsPages,
  getKeywordStats,
}) => {

  const [sortName, setSortName] = useState("updated_at");
  const [sortType, setSortType] = useState("DESC");

  const [keyword, setKeyword] = useState("");
  useEffect(() => {
    const queryObj = queryString.parse(location.search)
    const defaultPage = queryObj.page ? queryObj.page : 1
    setKeyword(queryObj.keyword)
    setTimeout(()=>{
      history.replace({
        pathname: '/statistics',
        search: location.search,
      })
      getKeywordStats({page: defaultPage, keyword: queryObj.keyword, columnName: sortName, order: sortType})
    }, 300)
  }, [])

  const toTime = (value) => {
    return moment(value, "YYYY-MM-DD hh:mm").format("YYYY-MM-DD hh:mm")
  }

  const changePage = ({page}) => {
    getKeywordStats({page: page, keyword: keyword, columnName: sortName, order: sortType})
    history.push(`/statistics?keyword=${keyword}&page=${page}`)
  }

  const getCurrentPage = () => {
    const queryObj = queryString.parse(location.search)
    return queryObj.page ? queryObj.page : 1
  }

  const getCurrentKeyword = () => {
    const queryObj = queryString.parse(location.search)
    return queryObj.keyword
  }

  const handleSortTable = (columnName) => {
    if(columnName === sortName) {
      switch(sortType) {
        case 'DESC':
          setSortType("ASC")
          return
        case 'ASC':
          setSortName("updated_at")
          setSortType("DESC")
          return
        default:
          return
      }
    } else {
      setSortName(columnName)
      setSortType("DESC")
      return
    }
  }

  useEffect(() => {
    const defaultPage = getCurrentPage();
    const keywordId = getCurrentKeyword();
    getKeywordStats({page: defaultPage, keyword: keywordId, columnName: sortName, order: sortType})
  }, [sortType, sortName]);

  return (
    <Fragment>
      <div className="tbl_header">
        Link Statistics
      </div>
      <div className="tbl">
        <div className="row">
          <div className="cell cell_header cell_visits" onClick={(e) => handleSortTable("created_at")}>
            <div className="header_clickable">Visits
              {sortName === "created_at" && (
                sortType === "DESC" ? (<i className="caret caret-down"></i>) : (<i className="caret caret-up"></i>)
              )}
            </div>
          </div>
          <div className="cell cell_header cell_stats" onClick={(e) => handleSortTable("country")}>
            <div className="header_clickable">Country
              {sortName === "country" && (
                sortType === "DESC" ? (<i className="caret caret-down"></i>) : (<i className="caret caret-up"></i>)
              )}
            </div>
          </div>
          <div className="cell cell_header cell_stats" onClick={(e) => handleSortTable("city")}>
            <div className="header_clickable">City
              {sortName === "city" && (
                sortType === "DESC" ? (<i className="caret caret-down"></i>) : (<i className="caret caret-up"></i>)
              )}
            </div>
          </div>
          <div className="cell cell_header cell_device" onClick={(e) => handleSortTable("device")}>
            <div className="header_clickable">Device
              {sortName === "device" && (
                sortType === "DESC" ? (<i className="caret caret-down"></i>) : (<i className="caret caret-up"></i>)
              )}
            </div>
          </div>
          <div className="cell cell_header cell_os" onClick={(e) => handleSortTable("os")}>
            <div className="header_clickable">OS
              {sortName === "os" && (
                sortType === "DESC" ? (<i className="caret caret-down"></i>) : (<i className="caret caret-up"></i>)
              )}
            </div>
          </div>
          <div className="cell cell_header cell_browser" onClick={(e) => handleSortTable("browser")}>
            <div className="header_clickable">Browser
              {sortName === "browser" && (
                sortType === "DESC" ? (<i className="caret caret-down"></i>) : (<i className="caret caret-up"></i>)
              )}
            </div>
          </div>
        </div>
        {statistics.map(elem => {
            return (
              <div className={`row ${elem.status}`} key={elem.id}>
                <div className="cell cell_visits">
                  <div className="wrap_link" data-tip={elem.ip} data-for={'key'+elem.ip}>{elem.created_at ? elem.created_at : 'NA'}</div>
                  <ReactTooltip id={'key'+elem.ip}>{'IP: '+elem.ip}</ReactTooltip>
                </div>
                <div className="cell cell_stats">
                  <div className="wrap_link">{elem.country ? elem.country : 'NA'}</div>
                </div>
                <div className="cell cell_stats">
                  <div className="wrap_link">{elem.city ? elem.city : 'NA'}</div>
                </div>
                <div className="cell cell_device">
                  <div className="wrap_keywords">{elem.device ? elem.device : 'NA'}</div>
                </div>
                <div className="cell cell_os">
                  <div className='wrap_link'>{elem.os ? elem.os : 'NA'}</div>
                </div>
                <div className="cell cell_browser">
                  <div className='wrap_link'>{elem.browser ? elem.browser : 'NA'}</div>
                </div>
              </div>
            )
          })
        }
        <div className="pagination">
          {
            keywordsPages &&
            <Pagination
            className="bg-red pagination-container"
            total={keywordsPages.total * 50}
            limit={50}
            pageCount={3}
            currentPage={keywordsPages.current_page}
          >
            {({
              pages,
              currentPage,
              hasNextPage,
              hasPreviousPage,
              previousPage,
              nextPage,
              totalPages,
              getPageItemProps
            }) => (
              <div className="pagination-buttons">
                <button
                  {...getPageItemProps({
                    pageValue: 1,
                    onPageChange: () => {changePage({page: 1})},
                  })}
                >
                  first
                </button>

                {hasPreviousPage && (
                  <button
                    {...getPageItemProps({
                      pageValue: previousPage,
                      onPageChange: () => {changePage({page: previousPage})}
                    })}
                  >
                    {'<'}
                  </button>
                )}

                {pages.map(page => {
                  let activePage = null;
                  if (currentPage === page) {
                    activePage = { backgroundColor: 'white', border:'inset', fontWeight: 'bold' };
                  }
                  return (
                    <button
                      {...getPageItemProps({
                        pageValue: page,
                        key: page,
                        style: activePage,
                        onPageChange: () => {changePage({page: page})}
                      })}
                    >
                      {page}
                    </button>
                  );
                })}

                {hasNextPage && (
                  <button
                    {...getPageItemProps({
                      pageValue: nextPage,
                      onPageChange: () => {changePage({page: nextPage})}
                    })}
                  >
                    {'>'}
                  </button>
                )}

                <button
                  {...getPageItemProps({
                    pageValue: totalPages,
                    onPageChange: () => {changePage({page: totalPages})}
                  })}
                >
                  last
                </button>
              </div>
            )}
          </Pagination>
          }
          
        </div>
      </div>
    </Fragment>
  );
};

Statistics.propTypes = {
  statistics: PropTypes.array,
  keywordsPages: PropTypes.object,
  removeTableItem: PropTypes.func,
  handleselect: PropTypes.func,
  removeTableItems: PropTypes.func,
  handleEditDescription: PropTypes.func,
  handleChecked: PropTypes.func,
  handleEditUrl: PropTypes.func,
  removeClickKeyword: PropTypes.func,
  reduceClickKeyword: PropTypes.func,
  changeExpiredTableItem: PropTypes.func,
  getKeywordStats: PropTypes.func,
  isShortedLink: PropTypes.bool
};

const mapStateToProps = state => ({
  statistics: state.statistic.statistics,
  keywordsPages: state.statistic.pages
});

const mapDispatchToProps = {
  handleSubmitUrl,
  removeTableItem,
  handleselect,
  removeTableItems,
  handleChecked,
  handleEditDescription,
  handleEditUrl,
  removeClickKeyword,
  reduceClickKeyword,
  changeExpiredTableItem,
  getKeywordStats
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
